// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-designtest-js": () => import("./../../../src/pages/designtest.js" /* webpackChunkName: "component---src-pages-designtest-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investigations-js": () => import("./../../../src/pages/investigations.js" /* webpackChunkName: "component---src-pages-investigations-js" */),
  "component---src-pages-lore-js": () => import("./../../../src/pages/lore.js" /* webpackChunkName: "component---src-pages-lore-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-places-js": () => import("./../../../src/pages/places.js" /* webpackChunkName: "component---src-pages-places-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tourcompanies-js": () => import("./../../../src/pages/tourcompanies.js" /* webpackChunkName: "component---src-pages-tourcompanies-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-investigation-js": () => import("./../../../src/templates/investigation.js" /* webpackChunkName: "component---src-templates-investigation-js" */),
  "component---src-templates-investigator-js": () => import("./../../../src/templates/investigator.js" /* webpackChunkName: "component---src-templates-investigator-js" */),
  "component---src-templates-lore-js": () => import("./../../../src/templates/lore.js" /* webpackChunkName: "component---src-templates-lore-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-place-js": () => import("./../../../src/templates/place.js" /* webpackChunkName: "component---src-templates-place-js" */),
  "component---src-templates-tourcompany-js": () => import("./../../../src/templates/tourcompany.js" /* webpackChunkName: "component---src-templates-tourcompany-js" */)
}

